import {useEffect, useState} from "preact/hooks";
import {LoanNFT} from "../../config.ts";
import JewelerNFT from "../../components/JewelerNFT/JewelerNFT.tsx";
import {API} from "../../api/Api.ts";
import LendDialog from "../../components/Dialogs/LendDialog/LendDialog.tsx";
import LendDialogCustomized from "../../components/Dialogs/LendDialogCustomized/LendDialogCustomized.tsx";
import {showTonConnectLoginPage, useUserAddress} from "../../tonConnect.ts";
import Content from "../../components/utils/Content.module.tsx";


export default function JewelerPage() {
    const userAddress = useUserAddress();
    const [nfts, setNFTS] = useState<LoanNFT[]>([]);
    useEffect(() => {
        API.getJewelerNFTS().then(setNFTS);
    });

    const [openedLend, setOpenedLend] = useState<LoanNFT|null>(null);
    const [openedCustomizedLend, setOpenedCustomizedLend] = useState<LoanNFT|null>(null);
    return (
        <Content>
            <h1>Nfts for loan</h1>
            <div style={{display: "flex", flexWrap: 'wrap'}}>
                {
                    nfts.map((nft) => (
                        <JewelerNFT nft={nft} onLend={async ()=>{
                            if(!userAddress) return showTonConnectLoginPage();
                            setOpenedLend(nft);
                        }} onCustomize={()=>{
                            if(!userAddress) return showTonConnectLoginPage();
                            setOpenedCustomizedLend(nft);
                        }} />
                    ))
                }
            </div>
            {!!openedCustomizedLend && <LendDialogCustomized nft={openedCustomizedLend} onClose={()=>setOpenedCustomizedLend(null)} onSuccess={v=>{
                setOpenedLend(v);
                setOpenedCustomizedLend(null);
            }}/>}

            {!!openedLend && <LendDialog nft={openedLend} onClose={()=>setOpenedLend(null)}/>}
        </Content>
    )
}