import {Address} from "@ton/core";

export interface LoanNFT {
    title: string;
    description: string;
    image: string;
    link: string;
    want_amount_ton: number;
    want_interest: number;
    want_period_days: number;
    id: number;
}


export const DefaultNFTS: LoanNFT[] = [
    {
        title: "NFT 1",
        description: "This is NFT 1",
        image: "https://i.getgems.io/zTmS3Eh76q3uJ8t4lbXdZ-5o0jGbd1gKOtd37NulDVI/rs:fill:250:250:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYi9jLzY1ZDc1NzlhMzZkMmI4MjMzMzM2YjZiMS9pbWFnZXMvYWM5ZDU3M2Q3ZWNkMjQ3OTQyMDY4NjMyNTllMDZjYmVjOWZhNmExMC5wcmV2LndlYnA.jpg",
        link: "https://example.com/nft1",
        want_amount_ton: 100,
        want_interest: 0.05,
        want_period_days: 30,
        id: 0
    },
    {
        title: "NFT 2",
        description: "This is NFT 2",
        image: "https://i.getgems.io/AKczsEQUFrFporDNbnwAj3NYdvQBKezmXQ34vS4hPhQ/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLXMzL25mdC1jb250ZW50LWNhY2hlL2ltYWdlcy9FUUJaWi1FTENaeERDX0FTRVJYTHJUQmMxVldmM3lLbVp1X1NLUEdVbzE5NGFFMTYvYmY3ZWUxNDZkNGE0NmEzYQ",
        link: "https://example.com/nft2",
        want_amount_ton: 200,
        want_interest: 0.06,
        want_period_days: 19,
        id: 1
    },
    {
        title: "NFT 3",
        description: "This is NFT 3",
        image: "https://i.getgems.io/WkWgXNWyI6OZMarHa2SgYdLE5d4eHdCqalUCsiBina8/rs:fill:500:500:1/g:ce/czM6Ly9nZXRnZW1zLXMzL25mdC1jb250ZW50LWNhY2hlL2ltYWdlcy9FUURKQVhaSkRRa0JQNnZDR24yN3ZXMDBHSWxVa19BUVhuVnRxTlYwVHEybkZVeUIvM2Q5NWQzYjdmYTU3MTJhZg",
        link: "https://example.com/nft3",
        want_amount_ton: 300,
        want_interest: 0.0723,
        want_period_days: 5,
        id: 2
    },
    {
        title: "NFT 4",
        description: "This is NFT 4",
        image: "https://i.getgems.io/QlPDOUAW4mze9oILKmj8-JY-LNXTT4ZiyWjNNN4ioaY/rs:fill:500:500:1/g:ce/czM6Ly9nZXRnZW1zLXMzL25mdC1jb250ZW50LWNhY2hlL2ltYWdlcy9FUURKQVhaSkRRa0JQNnZDR24yN3ZXMDBHSWxVa19BUVhuVnRxTlYwVHEybkZVeUIvNTUzNjVjY2RjODJhM2I5Mw",
        link: "https://example.com/nft4",
        want_amount_ton: 400,
        want_interest: 0.023,
        want_period_days: 10,
        id: 3
    },
    {
        title: "NFT 5",
        description: "This is NFT 5",
        image: "https://i.getgems.io/Ou0mDKpdmwkEE-U8fcRVRDCeXoOn6BCM3T-YolxisaM/rs:fill:500:500:1/g:ce/czM6Ly9nZXRnZW1zLXMzL25mdC1jb250ZW50LWNhY2hlL2ltYWdlcy9FUURKQVhaSkRRa0JQNnZDR24yN3ZXMDBHSWxVa19BUVhuVnRxTlYwVHEybkZVeUIvMGY5OWNiMzdmMmRjMjM3Zg",
        link: "https://example.com/nft5",
        want_amount_ton: 500,
        want_interest: 0.012,
        want_period_days: 20,
        id: 4
    }
];

export const API_URL = "http://localhost:3000/api"


export function nftLink(address: string) {
    address = Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.getgems.io/collection/1/" + address;
    return "https://getgems.io/collection/1/" + address;
}

export function getTonViewerLink(address: string | Address) {
    address = address instanceof Address ? address : Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.tonviewer.com/" + address;
    return "https://tonviewer.com/" + address;
}

export function getGetgemsLink(address: string) {
    address = Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.getgems.io/collection/1/" + address;
    return "https://getgems.io/collection/1/" + address;
}

export function getTonScanLink(address: string) {
    address = Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.tonscan.org/address/" + address;
    return "https://tonscan.org/address/" + address;
}

export interface IJetton {
    title: string;
    decimals: number;
    icon: string;
    address: Address | null;
}

export const DefaultJettons: IJetton[] = [
    {
        title: 'TON',
        icon: 'https://ton.org/icons/custom/ton_logo.svg',
        address: null,
        decimals: 9
    },
    (
        import.meta.env.VITE_NETWORK === 'testnet' ? (
            {
                title: 'AIR',
                icon: 'https://testnet.tonviewer.com/assets/images/stub-image-black.png',
                address: Address.parse('kQAp_H-fVRrcAhNS7LaXGQ4GsP_yBQT98t0kwNohtaUjLg7r'),
                decimals: 9
            }
        ) : ({
                title: 'USDT',
                icon: 'https://cache.tonapi.io/imgproxy/T3PB4s7oprNVaJkwqbGg54nexKE0zzKhcrPv8jcWYzU/rs:fill:200:200:1/g:no/aHR0cHM6Ly90ZXRoZXIudG8vaW1hZ2VzL2xvZ29DaXJjbGUucG5n.webp',
                address: Address.parse('EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'),
                decimals: 6
            }
        )
    ),
    ...(import.meta.env.VITE_NETWORK === 'testnet' ? [{
        title: 'LOM',
        icon: 'https://cache.tonapi.io/imgproxy/kQsktET2A2ITMzAqQSfylnyRCFxUR_6OhtcQDVNH7R0/rs:fill:200:200:1/g:no/aHR0cHM6Ly9hdmF0YXJmaWxlcy5hbHBoYWNvZGVycy5jb20vMzY3LzM2NzIxNy5qcGc.webp',
        address: Address.parse('kQC6cYfMFYFur2IgJroc3wBxg-q4hOxsqGQwEYSEARxtOmZf'),
        decimals: 5
    }] : [])
]


//jetton for test - kQAYX4gVF__o3DIEdpaQB641gEDktCa4Q4kZpzWak_KN-15E




