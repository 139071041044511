import {DefaultNFTS} from "../config.ts";


class RestAPI{
    async getJewelerNFTS(){
        return DefaultNFTS;
    }

    async getUserNFTS(){

    }
}


export const API = new RestAPI();

