import {LoanNFT} from "../../config.ts";
import Styles from "./JewelerNFT.module.scss";
import Button from "../buttons/Button.tsx";

export default function JewelerNFT({nft, onLend, onCustomize}: {nft: LoanNFT, onLend: (nft: LoanNFT)=>void, onCustomize: (nft: LoanNFT)=>void}) {
    return (
        <div class={Styles.jewelerNFT}>
            <h1 class={Styles.title}>{nft.title}</h1>
            {/*<p class={Styles.description}>{nft.description}</p>*/}
            <img src={nft.image} alt={nft.title} class={Styles.image}/>
            <p class={Styles.price}>{nft.want_amount_ton} TON {Math.round(nft.want_interest * 10000) / 100}%</p>
            <p class={Styles.days}>{nft.want_period_days}d</p>
            <div class={Styles.buttons}>
                <Button onClick={()=>onLend(nft)}>Lend</Button>
                <Button onClick={()=>onCustomize(nft)}>Customize</Button>
            </div>
        </div>
    );
}
